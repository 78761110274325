import React, { useEffect } from "react"
import { isMobile } from "react-device-detect"
import ReactGA from "react-ga"

import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from "@material-ui/icons/Facebook"
import InstagramIcon from "@material-ui/icons/Instagram"
import TwitterIcon from "@material-ui/icons/Twitter"

import TopPersons from "../components/top-persons"
import TopBooks from "../components/top-books"
import Header from "../components/header"
import Footer from "../components/footer"

const colors = {
  "sky blue": "#68C4E3",
  chocolate: "#D86B31",
  "light steel blue": "#D3E5DD",
  "dark olive green": "#33743A",
  "steel blue": "#496BA4"
}

export default function Home() {
  useEffect(() => ReactGA.pageview("/"))

  return (
    <>
      <Header />
      <div style={{ marginTop: "75px" }}>
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between"
            }}
          >
            <div
              id="back-to-top-anchor"
              style={{
                flex: "0 1 auto",
                maxWidth: "500px",
                alignSelf: "flex-start",
                paddingTop: 20
              }}
            >
              <Typography variant="h3" paragraph>
                Find <u style={{ color: colors["steel blue"] }}>next book</u> to
                read!
              </Typography>

              <Typography variant="h5" color="textSecondary" paragraph>
                Explore our library of the most recommended books by well
                renowned people.
              </Typography>
            </div>

            {!isMobile && (
              <div
                style={{
                  marginTop: 16
                }}
              >
                <img
                  src={require("../images/nextbook.gif")}
                  alt="Logo"
                  height="192"
                />
              </div>
            )}

            {!isMobile && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <a
                  href="https://www.facebook.com/nextbooktoread/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton>
                    <FacebookIcon style={{ color: "#3b5998" }} />
                  </IconButton>
                </a>
                <a
                  href="https://www.instagram.com/nextbookrecommender/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton>
                    <InstagramIcon style={{ color: "salmon" }} />
                  </IconButton>
                </a>
                <a
                  href="https://twitter.com/nextbooktoread"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton>
                    <TwitterIcon style={{ color: "#1DA1F2" }} />
                  </IconButton>
                </a>
              </div>
            )}
          </div>
        </Container>

        <TopBooks />
        <TopPersons />
      </div>
      <Footer/>
    </>
  )
}

import React from "react"
import { navigate } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Fab from "@material-ui/core/Fab"
import Skeleton from "@material-ui/lab/Skeleton"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"

import { usePersons } from "../hooks/use-persons"

const topPersonAliases = [
  "elon-musk",
  "bill-gates",
  "barack-obama",
  "emma-watson",
  "mark-zuckerberg",
  "trevor-noah"
]

export default function TopPersons() {
  const persons = usePersons()

  const handlePersonClick = alias => {
    navigate("/persons/" + alias, { state: { alias: alias } })
  }

  return (
    <Container style={{ marginTop: 32 }}>
      <Typography variant="h5" style={{ marginBottom: "8px" }}>
        {persons.length === 0 ? "Loading top people..." : "Top people"}
      </Typography>
      <Grid container spacing={4}>
        {persons.length === 0
          ? [...Array(6)].map(temp => (
              <Grid
                container
                item
                xs={6}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                key={temp}
              >
                <Card style={{ borderRadius: 10, borderColor: "#496BA4" }}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={170}
                    width={200}
                  />
                  <CardContent>
                    <Skeleton animation="wave" variant="text" height={20} />
                  </CardContent>
                </Card>
              </Grid>
            ))
          : persons.map(
              person =>
                topPersonAliases.includes(person.alias) && (
                  <Grid
                    item
                    key={person.alias}
                    xs={6}
                    sm={3}
                    md={3}
                    lg={2}
                    xl={2}
                    style={{ display: "flex" }}
                  >
                    <Card style={{ borderRadius: 10, borderColor: "#496BA4" }}>
                      <CardActionArea
                        onClick={() => handlePersonClick(person.alias)}
                      >
                        <CardMedia
                          component="img"
                          src={require("../images/persons/" +
                            person.alias +
                            ".jpg")}
                        />

                        <CardContent>
                          <Typography>{person.name}</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
            )}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 32,
          marginBottom: 16
        }}
      >
        <Fab
          onClick={() => navigate("persons")}
          variant="extended"
          color="primary"
          aria-label="see-all-people"
        >
          See all people
          <DoubleArrowIcon />
        </Fab>
      </div>
    </Container>
  )
}

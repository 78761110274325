import React from "react"
import { navigate } from "gatsby"

import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardMedia from "@material-ui/core/CardMedia"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Fab from "@material-ui/core/Fab"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import Skeleton from "@material-ui/lab/Skeleton"

import { useBooks } from "../hooks/use-books"

const topBookAliases = [
  "zero-to-one",
  "shoe-dog",
  "becoming",
  "harry-potter-the-complete-collection-17",
  "sapiens",
  "surely-youre-joking-mr-feynman"
]

export default function Topbooks() {
  const books = useBooks()

  const handleBookClick = alias => {
    navigate("/books/" + alias, { state: { alias: alias } })
  }

  return (
    <Container style={{ marginTop: 32 }}>
      <Typography variant="h5" style={{ marginBottom: "8px" }}>
        {books.length === 0 ? "Loading top books..." : "Top books"}
      </Typography>
      <Grid container spacing={4}>
        {books.length === 0
          ? [...Array(6)].map(temp => (
              <Grid
                container
                item
                xs={6}
                sm={3}
                md={3}
                lg={2}
                xl={2}
                key={temp}
              >
                <Card style={{ borderRadius: 10, borderColor: "#496BA4" }}>
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    height={250}
                    width={200}
                  />
                  <CardContent>
                    <Skeleton animation="wave" variant="text" height={20} />
                  </CardContent>
                </Card>
              </Grid>
            ))
          : books.map(
              book =>
                topBookAliases.includes(book.alias) && (
                  <Grid
                    item
                    key={book.alias}
                    xs={6}
                    sm={3}
                    md={3}
                    lg={2}
                    xl={2}
                    style={{ display: "flex" }}
                  >
                    <Card style={{ borderRadius: 10, borderColor: "#496BA4" }}>
                      <CardActionArea
                        onClick={() => handleBookClick(book.alias)}
                      >
                        <CardMedia
                          component="img"
                          src={require("../images/books/" +
                            book.alias +
                            ".jpg")}
                        />

                        <CardContent>
                          <Typography>{book.title}</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
            )}
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 32,
          marginBottom: 16
        }}
      >
        <Fab
          onClick={() => navigate("books")}
          variant="extended"
          color="primary"
          aria-label="see-all-books"
        >
          See all books
          <DoubleArrowIcon />
        </Fab>
      </div>
    </Container>
  )
}

import React from "react"

import SEO from "../components/seo"
import Home from "../components/home"
import "../components/index.css"

const IndexPage = () => (
  <>
    <SEO />
    <Home />
  </>
)

export default IndexPage
